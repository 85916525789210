import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { NoImageDiv } from "components/shared/NoImageDiv"
import colors from "helpers/colors"
// import { Heart } from "components/models/likes"
import { urls } from "helpers/urls"
import { generateDateTerm } from "libs"
import { first } from "lodash"
import Image from "next/future/image"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import { VFC } from "react"
import { CSSProperties } from "styled-components"

type Props = {
  event?: EventCardType
  style?: CSSProperties
}

export const EventCard: VFC<Props> = ({ event, style }) => {
  const { title, event_places, main_event_image_url } = event || {}
  // const [isLike, setIsLike] = useState(initialIsLike || false)
  const { name: placeName } = first(event_places) || {}
  // const isMultipleSpace = eventPlaces.length > 1
  // const mainEventPlace = eventPlaces[0]
  const { xs } = useBreakpoint()
  const { t } = useTranslation()

  // const handleLike = () => {
  //   // TODO
  //   return (
  //     setIsLike(!isLike)
  //   )
  // }

  if (!event) {
    return null
  }

  return (
    <div style={{ width: xs ? 160 : 270, minWidth: xs ? 165 : 270, alignSelf: "flex-start", ...style }}>
      <Link href={urls.event(event.id)}>
        <a>
          <div style={{ display: "flex", justifyContent: "center", position: "relative", marginBottom: 8 }}>
            {main_event_image_url ? (
              <Image src={main_event_image_url} alt={event.title} style={{ width: "100%", height: xs ? 100 : 162, objectFit: "cover" }} />
            ) : (
              <div style={{ width: xs ? 160 : 270, height: xs ? 100 : 162 }}>
                <NoImageDiv type="event" />
              </div>
            )}
            {event.active_lottery && (
              <div style={{ background: "rgba(0, 0, 0, 0.30)", position: "absolute", lineHeight: 1, left: 8, bottom: 9, padding: "4px 8px", height: 24 }}>
                <Typography.Text style={{ fontSize: 12, lineHeight: 1, color: "white" }}>{t("エントリー")}</Typography.Text>
              </div>
            )}
            {/* <Heart isLike={isLike} onClick={handleLike} /> */}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4" }}>
            <Typography.Title
              level={3}
              ellipsis={{ rows: 2 }}
              style={{ fontSize: 14, fontWeight: 700, margin: 0, lineHeight: 1.5 }}>
              {title}
            </Typography.Title>
            <Typography.Text ellipsis style={{ fontSize: 14, lineHeight: 1.5 }}>
              {placeName}
            </Typography.Text>
            <Typography.Text ellipsis style={{ fontSize: 14, lineHeight: 1.5, color: colors.grey30 }}>
              {generateDateTerm({ start: event.start_at, end: event.end_at, connectingText: "〜" })}
            </Typography.Text>
          </div>
        </a>
      </Link>
    </div>
  )
}
