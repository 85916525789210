import { message, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import api from "api"
import { FollowButton } from "components/models/follows"
import { NoImageDiv } from "components/shared/NoImageDiv"
import { urls } from "helpers/urls"
import Image from "next/future/image"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import MapIcon from "public/icons/map.svg"
import { useCallback, useMemo, useState, VFC } from "react"

type Props= {
  owner?: OwnerCardType
  addressType: "full" | "short"
}

export const OwnerCard: VFC<Props> = ({ owner, addressType }) => {
  const { id, name, default_address, cover_url, following_status } = owner || {}
  const { xs } = useBreakpoint()
  const { t } = useTranslation()
  const [isFollow, setIsFollow] = useState(following_status || false)

  const address = useMemo(() => {
    if (addressType === "full" && default_address?.address) {
      return default_address.address
    }

    const parts = [default_address?.city, default_address?.state].filter(Boolean)
    if (parts.length > 0) {
      return parts.join(", ")
    }

    return "N/A"
  }, [addressType, default_address?.address, default_address?.state, default_address?.city])

  const handleFollow = useCallback(async () => {
    if (isFollow) {
      const { data, error }: { data: any; error: any } = await api.delete(`/owners/${id}/unfollow`)
      if (data) {
        message.success(t("フォローを解除しました"))
      }
      if (error) {
        message.error(error.data.error)
        return
      }
    } else {
      const { data, error }: { data: any; error: any } = await api.post(`/owners/${id}/follow` )
      if (data) {
        message.success(t("フォローしました"))
      }
      if (error) {
        message.error(error.data.error)
        return
      }
    }
    setIsFollow(!isFollow)
  }, [id, isFollow, t])

  if (!owner) {
    return null
  }

  return (
    <div style={{ width: xs ? 160 : 270 }}>
      <div style={{ height: xs ? 100 : 162, width: xs ? 160 : 270 }}>
        <Link href={urls.owner(owner.id)} passHref>
          <a>
            {cover_url ? (
              <Image
                src={cover_url}
                alt={owner.name}
                style={{
                  width: xs ? 160 : 270,
                  height: xs ? 100 : 162,
                  objectFit: "cover"
                }}
                sizes={xs ? "50vw" : "20vw"}
              />
            ) : (
              <div style={{ width: xs ? 160 : 270, height: xs ? 100 : 162 }}>
                <NoImageDiv type="event" />
              </div>
            )}
          </a>
        </Link>
      </div>
      <div style={{ marginTop: 8, display: "flex", alignItems: "center", gap: "8px", height: "42px" }}>
        {owner.online_store_icon_url && (
          <div style={{ minWidth: "40px", minHeight: "40px", height: "40px" }}>
            <Link href={urls.owner(owner.id)}>
              <a>
                <Image src={owner.online_store_icon_url} alt={owner.name} width={40} height={40} />
              </a>
            </Link>
          </div>
        )}
        <Link href={urls.owner(owner.id)}>
          <a>
            <Typography.Title
              ellipsis={{ rows: 2 }}
              level={2}
              style={{
                margin: 0,
                fontSize: 14,
                lineHeight: 1.5,
                fontWeight: "normal",
                flex: 1,
              }}
            >
              {name}
            </Typography.Title>
          </a>
        </Link>
        {!xs && (
          <div style={{ marginLeft: "auto" }}>
            <FollowButton style={{ height: 30 }} isFollow={isFollow} onClick={handleFollow} />
          </div>
        )}
      </div>
      <div style={{ justifyItems: "center", alignItems: "center", display: "flex", gap: 2, marginTop: 4, marginBottom: xs ? 8 : 0 }}>
        <div style={{ height: "16px" }}>
          <MapIcon width={12} height={12} fill="#232427" />
        </div>
        <Typography.Text ellipsis style={{ fontSize: 12, lineHeight: 1 }}>
          {address}
        </Typography.Text>
      </div>
      {xs && <FollowButton isFollow={isFollow} onClick={handleFollow} />}
    </div>
  )
}
