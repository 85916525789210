import api from "api"
import useSWR from "swr"

export type EventType = Event & {
  owner: Owner
  active_lottery?: Lottery
}

/**
 * イベントを取得するSWR
 */
export const useEvent = (id:string | undefined) => {
  return useSWR<EventType>(id ? `/events/${id}` : null, async (url) => {
    const { data, error } : { data: any, error: any } = await api.get(url)
    if (error) {
      throw error
    }
    return data
  }, {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  })
}
