import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import colors from "helpers/colors"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import { FC } from "react"

export const Section: FC<{ title: string, link?:string }> = ({ title, link, children }) => {
  const { xs } = useBreakpoint()
  const { t } = useTranslation()

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: xs ? "8px" : "16px",
          borderBottom: `1px solid ${colors.grey}`,
          alignItems: "center"
        }}
      >
        <Typography.Text style={{ fontSize: 22, fontWeight: 500, lineHeight: 1.5, letterSpacing: 0.8 }}>
          {title}
        </Typography.Text>
        {link && (
          <Link href={link}>
            <a style={{ fontSize: 14, fontWeight: 500, lineHeight: 1, letterSpacing: 0.8 }}>
              {t("一覧を見る")}
            </a>
          </Link>
        )}
      </div>
      {children}
    </div>
  )
}
