import { Button } from "components/shared/Button"
import colors from "helpers/colors"
import { useTranslation } from "next-i18next"
import { CSSProperties, VFC } from "react"

type Props = {
  style?: CSSProperties
  isFollow: boolean
  onClick: () => void
}
export const FollowButton: VFC<Props> = ({ style, isFollow, onClick }) => {
  const { t } = useTranslation()
  if (isFollow) {
    return (
      <Button
        style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: colors.black, color: colors.white, height: "30px", ...style }}
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        size='sm'
      >
        {t("フォロー中")}
      </Button>
    )
  }

  return (
    <Button
      style={{ display: "flex", alignItems: "center", justifyContent: "center", ...style }}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      size='sm'
      type='secondary'
    >
      {t("フォロー")}
    </Button>
  )
}
