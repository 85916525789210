import Text from "components/shared/Text"
import Link from "next/link"
import { Image, View } from "react-native"

export const Owner = ({ owner }: { owner: Owner }): JSX.Element => {
  return (
    <Link href="/">
      <a>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowRadius: 3,
            shadowOpacity: 0.3,
            elevation: 3,
            borderRadius: 4,
            height: 80,
            paddingHorizontal: 16,
          }}
        >
          {owner.image_url && (
            <Image
              source={{ uri: owner.image_url }}
              style={{ width: 64, height: 64, marginRight: 12 }}
            />
          )}
          <Text
            accessibilityRole="header"
            aria-level="2"
            style={{ fontSize: 16, fontWeight: "600" }}
          >
            {owner.name}
          </Text>
        </View>
      </a>
    </Link>
  )
}

